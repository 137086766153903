<template>
  <div>
    <v-row>
      <v-col cols="12" md="4" xs="12">
        <v-select
          label="Tag"
          background-color="white"
          v-model="selDay"
          :items="Days"
        ></v-select>
      </v-col>
      <v-col cols="12" md="4" xs="12">
        <v-select
          label="Monat"
          background-color="white"
          v-model="selMonth"
          :items="Months"
          item-value="id"
          item-text="name"
          return-object
        ></v-select>
      </v-col>
      <v-col cols="12" md="4" xs="12">
        <v-select
          style="min-width: 100px"
          label="Jahr"
          background-color="white"
          v-model="selYear"
          :items="Years"
          item-value="id"
          item-text="name"
        ></v-select>
      </v-col>
    </v-row>

    <!-- <v-layout wrap row justify-end style="text-align: right">
      <v-flex shrink xs12 sm12 md12 style="max-width: 80px" ps-4>
        <v-select
          label="Tag"
          background-color="white"
          v-model="selDay"
          :items="Days"
        ></v-select>
      </v-flex>

      <v-flex shrink xs12 sm12 md12 style="max-width: 100px" pl-4>
        <v-select
          label="Monat"
          background-color="white"
          v-model="selMonth"
          :items="Months"
          item-value="id"
          item-text="name"
          return-object
        ></v-select>
      </v-flex>

      <v-flex shrink xs12 sm12 md12 style="max-width: 120px" pl-4>
        <v-select
          style="min-width: 100px"
          label="Jahr"
          background-color="white"
          v-model="selYear"
          :items="Years"
          item-value="id"
          item-text="name"
        ></v-select>
      </v-flex>
    </v-layout> -->
  </div>
</template>

<script>
  export default {
    name: "input-geburtstag-select",
    components: {},
    props: {
      day: {
        type: Number,
        default: new Date().getDate(),
      },
      month: {
        type: Number,
        default: new Date().getMonth(),
      },
      year: {
        type: Number,
        default: new Date().getYear() + 1900,
      },
    },
    data: (vm) => ({
      Months: [
        { id: 1, name: "Januar" },
        { id: 2, name: "Februar" },
        { id: 3, name: "März" },
        { id: 4, name: "April" },
        { id: 5, name: "Mai" },
        { id: 6, name: "Juni" },
        { id: 7, name: "Juli" },
        { id: 8, name: "August" },
        { id: 9, name: "September" },
        { id: 10, name: "Oktober" },
        { id: 11, name: "November" },
        { id: 12, name: "Dezember" },
      ],
      Days: [],
      Years: [],
      selMonth: {},
      selYear: parseInt(vm.year),
      selDay: parseInt(vm.day),
    }),
    created: function() {
      let yearNow = new Date().getYear() + 1900;
      this.Years = this.computeYears(this.year - 120, yearNow);
      this.initMonth();
    },
    computed: {},
    watch: {
      year(val) {
        this.selYear = val;
      },
      month() {
        this.initMonth();
      },
      day(val) {
        this.selDay = val;
      },
      selMonth(val) {
        this.Days = this.computeDays();
        if (this.Days.length < this.selDay) {
          this.selDay = this.Days.length;
        }
        if (val && val.id) {
          this.$emit("update:month", val.id);
        }
      },
      selYear(val) {
        this.Days = this.computeDays();
        this.$emit("update:year", val);
      },
      selDay(val) {
        this.$emit("update:day", val);
      },
    },
    methods: {
      initMonth: function() {
        if (this.month) {
          let monthInit = this.month;
          this.selMonth = this.Months.filter(
            (month) => month.id == monthInit
          )[0];
        } else {
          let monthNow = new Date().getMonth() + 1;
          this.selMonth = this.Months.filter(
            (month) => month.id == monthNow
          )[0];
        }
        // this.$log.debug('initMonth')
        // this.$log.debug(this.selMonth);
      },
      numDaysInMonth: function(year, month) {
        return new Date(year, month, 0).getDate();
      },
      computeDays: function() {
        let list = [];

        let num = this.numDaysInMonth(this.selYear, this.selMonth.id);
        // this.$log.debug('sel', this.selDay, this.selMonth.id, this.selYear);
        // this.$log.debug('num', num);

        for (let day = 1; day <= num; day++) {
          if (day > 31) break;
          list.push(day);
        }
        return list;
      },
      computeYears: function(start, end) {
        let list = [];
        for (let y = start; y <= end; y++) {
          list.push({ id: y, name: y });
        }
        return list.reverse();
      },
    },
  };
</script>

<style scoped></style>
