// Date.getMonth 
// An integer number, between 0 and 11, representing the month in the given date according to local time. 0 corresponds to January, 1 to February, and so on.

export default class DateHelper {
    static calcAge(d, m, y) {

        let dateNow = new Date();


        let age = dateNow.getFullYear() - parseInt(y);
        console.log(dateNow.getFullYear());
        console.log(parseInt(y));
        // old
        /*   if ( d > dateNow.getDate() && m >= (dateNow.getMonth() + 1) ) {
              age--;
          } */

        if (dateNow.getMonth() < (m - 1)) {
            age--;
        }
        if (((m - 1) == dateNow.getMonth()) && (dateNow.getDate() < d)) {
            age--;
        }



        return parseInt(age);
    }
}